import { Column } from 'primereact/column';
import { CustomDataTable, tableOptions } from '../components/CustomDatatable';
import NotifyController from '../utilities/Toast';
import FeedbackService from '../service/FeedbackService';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useState } from 'react';


const GeneratedImage = () => {
    const navigate = useNavigate();
    const { page } = useParams();

    const [refresh, setRefresh] = useState<boolean>(false)
    const [searchParams] = useSearchParams();
    const [tableOptions, setTableOptions] = useState<{
        rowPerPage: number;
        filter: any;
        filters?: any;
        page: number;
        order?: string;
        orderType?: 1 | 0 | -1 | undefined | null;
    }>((searchParams.get('query')
        ? JSON.parse(decodeURIComponent(searchParams.get('query') as string))
        : (sessionStorage.getItem('gi_query')
            ? JSON.parse(sessionStorage.getItem('gi_query') as string) : undefined)));

    const onOptionChange = async (option: tableOptions) => {
        navigate(`?query=${encodeURIComponent(JSON.stringify(option))}`);
        sessionStorage.setItem('gi_query', JSON.stringify(option));
        const generatedImages = await FeedbackService.getInstance().getListGeneratedImage(
            option.page,
            option.rowPerPage,
            option.orderType,
            option.order,
            option.filter,
            option.selectedCol,
        ).catch((error) => {
            NotifyController.error(error?.message)
            console.log(error);
        })
        return { total: generatedImages?.data?.totalRecord ?? 0, data: generatedImages?.data?.items ?? [] }
    }

    const bodyAssetUrl = (rowData: any) => {
        return rowData.url_v2.map((item: any) => {
            return (
                <div className="p-3">
                    <Image src={item} alt={item} width="120" preview />
                </div>
            )
        })
    };

    const bodyFormattedDateTime = (rowData: any) => {
        const timestamp = rowData.created_ts;
        const date = new Date(timestamp); // Convert the timestamp to a Date object
        date.setHours(date.getHours() + 7); // Adjust for GMT+7 timezone

        // Format the date as needed (e.g., 'yyyy-MM-dd HH:mm:ss' format)
        const formattedDateTime = date.toISOString().replace("T", " ").split(".")[0];

        return (
            <span>{formattedDateTime}</span>
        );
    };

    const handleDetailButtonClick = (rowData: any) => {
        navigate(`/generated-image/detail/${rowData._id}`)
    };

    const handlePageChange = (page: any) => {
        navigate(`/generated-image/${page}`)
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <h5>Generated Image</h5>
                    <CustomDataTable
                        onOptionChange={onOptionChange}
                        refresh={refresh}
                        page={parseInt(page ?? '1')}
                        handlePageChange={handlePageChange}
                        tableOption={tableOptions}
                    >
                        <Column
                            body={(rowData: any) => (
                                <Button onClick={() => handleDetailButtonClick(rowData)}>Detail</Button>
                            )}
                            header="Action"
                            headerStyle={{ width: '6rem', textAlign: 'center' }}
                            style={{ textAlign: 'center' }}
                        />
                        <Column field="created_ts" sortable body={bodyFormattedDateTime} header="Created" headerStyle={{ minWidth: '10rem' }}></Column>
                        <Column field="is_sub" header="Is Sub" sortable filter headerStyle={{ minWidth: '10rem' }} ></Column>
                        <Column field="tool_type" header="Type" sortable headerStyle={{ minWidth: '10rem' }} ></Column>
                        <Column field="url_v2" body={bodyAssetUrl} header="Asset" headerStyle={{ width: 120 }}></Column>
                        <Column field="status" header="Status" sortable headerStyle={{ minWidth: '10rem' }} ></Column>
                        <Column field="device_token" header="Device ID" sortable headerStyle={{ minWidth: '10rem' }} ></Column>
                        <Column field="reason" header="Reason" sortable headerStyle={{ minWidth: '10rem' }} ></Column>
                    </CustomDataTable>

                </div>
            </div>
        </div>
    );
};

export default GeneratedImage;
